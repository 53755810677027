@import './normalize.css';

:root {
  --btn-bg-primary: hsl(130, 43%, 90%);
  --btn-bg-secondary: hsl(131, 20%, 46%);
  --inset-shadow: hsl(0, 0%, 12%);
  --outset-shadow: hsl(0, 0%, 41%);
  --dark-text: hsl(0, 0%, 10%);
  --light-text: hsl(0, 0%, 96%);
  --warning-text: hsl(0, 50%, 50%);
  --warning-btn-bg: hsl(0, 50%, 50%);
  --nav-height: 10vh;
  --max-width: 1600px;
  --light-border: lightgrey;
  --border-radius: 12px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

a {
  text-decoration: none;
}

button, .button, a.button, a, a:visited {
  color: var(--dark-text);
}

button, .button {
  border: 1px solid;
  border-radius: 9999px;
  transition: all 200ms;
}

button:hover, .button:hover {
  cursor: pointer;
}

button.active, .button.active {
  box-shadow: inset 0 0 .5em var(--inset-shadow);
}

button, input::placeholder, .button, .nav-sidebar a, .loading-container * {
  user-select: none;
}

.btn-confirm {
  border-color: var(--btn-bg-secondary);
  background-color: var(--btn-bg-primary);
}

.btn-confirm:hover,
button.active,
.button.active {
  border-color: var(--btn-bg-secondary);
  background-color: var(--btn-bg-secondary);
  color: var(--light-text);
}

.btn-warning {
  border-color: var(--warning-text);
  color: var(--warning-text);
}

.btn-warning:hover {
  background: var(--warning-btn-bg);
  color: var(--light-text);
}

.button.small {
  padding: .5em;
}

.button.med {
  padding: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input[type=search], input {
  -webkit-appearance: none;
  border-radius: 0;
}

nav, footer {
  background: var(--dark-text);
}

footer {
  color: var(--light-text);
  padding: 1em 0;
  min-height: 10vh;
  position: sticky;
  bottom: 0;
  width: 100%;
}

footer p {
  margin: 0 0 1em 0;
}

footer p a {
  color: var(--btn-bg-primary);
}

.footer-margin {
  margin-bottom: 10vh;
}

main {
  max-width: var(--max-width);
  min-height: calc(100vh - (var(--nav-height) + 10vh));
  margin: 0 auto;
}

.alternate-row {
  background: var(--light-text);
}

.center-text {
  text-align: center;
}

.rotated-background {
  position: relative;
}

.rotated-background::before {
  content: '';
  position: absolute;
  top: -42px;
  background-color: var(--btn-bg-primary);
  width: 6000px;
  height: 113%;
  z-index: -9999;
  left: -3000px;
}

.rotated-background:nth-of-type(1)::before {
  transform: rotate(14deg);
}

.rotated-background:nth-of-type(3)::before {
  transform: rotate(13deg);
}

.bordered {
  border: 1px solid var(--light-border);
}

.container {
  border-radius: var(--border-radius);
  padding: 1em;
}

.shadow {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.col-1 {
  grid-template-columns: 1fr;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}

.col-3 {
  grid-template-columns: repeat(3, 1fr);
}

.col-4 {
  grid-template-columns: repeat(4, 1fr);
}

.col-7 {
  grid-template-columns: repeat(7, 1fr);
}

.col-8 {
  grid-template-columns: repeat(8, 1fr);
}

.centered {
  justify-items: center;
}

.card {
  padding: 1em;
  margin-top: .25em;
}

.card h2 {
  font-size: 1.2em;
  font-weight: 500;
}

.dialog-container,
.loading-container {
  position: absolute;
  top: var(--nav-height);
  left: 0;
  background: rgba(0, 0, 0, 0.425);
  width: 100%;
  height: 100vh;
  display: grid;
}

.dialog-form button {
  margin: 0 1em;
}

.dialog {
  background: whitesmoke;
  width: 90%;
  place-self: center;
  text-align: center;
  padding: 1em 0;
  border-radius: var(--border-radius);
}

.display-block {
  display: block;
}

.input {
  border: 1px solid var(--btn-bg-secondary);
  border-radius: var(--border-radius);
  height: 2em;
  margin-top: .25em;
}

.grid {
  display: grid;
}

.grid-col-span-2 {
  grid-column: span 2;
}

.center-self {
  place-self: center;
}

.place-self-start {
  place-self: start;
}

.jst-cont-center {
  justify-content: center;
}

.align-cont-center {
  align-content: center;
}

.jst-item-center {
  justify-items: center;
}

.align-item-center {
  align-items: center;
}

span.loading {
  display: inline-block;
  height: 10px;
  width: 10px;
  padding: 5px;
  background-color: var(--btn-bg-primary);
  border-radius: 50%;
}

.loading.active {
  background-color: var(--btn-bg-secondary);
  transition: background-color ease-in 200ms;
}

.loading-container {
  top: 0;
  background: #fff;
  z-index: 99999999;
}

.loading-container p, footer {
  font-size: .75em;
}

.edit-btn, .delete-btn {
  padding: .25em .5em;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  color: var(--btn-bg-secondary);
}

.edit-btn {
  position: absolute;
  top: 1em;
  right: 1em;
}

.delete-btn {
  color: var(--warning-text);
}

/*! Begin custom input styling*/

.form-input {
  position: relative;
  padding: 1em 0 0;
  margin-top: 1em;
}

.form-input input {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--outset-shadow);
  outline: 0;
  padding: .33em 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form-input input::placeholder {
  color: transparent;
}

.form-input input:placeholder-shown~.form-label {
  cursor: text;
  top: 1em;
}

.form-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 150ms;
  color: var(--outset-shadow);
}

.form-input input:focus {
  padding-bottom: 6px;
  border-width: 3px;
}

.form-input input:focus~.form-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 150ms;
  color: inherit;
}

/* reset input */

.form-input input:required,
.form-input input:invalid {
  box-shadow: none;
}

/*! End custom input styling*/
.phone-img {
  border-radius: var(--border-radius);
}

.img-link {
  background-size: cover;
  background-position: center;
  display: grid;
  place-content: center start;
}

.img-link:hover, #create:hover {
  background-position-x: left;
  transition: all 60s cubic-bezier(0.16, 1, 0.3, 1);
}

#history:hover {
  background-position: center -10em;
}

.img-link span {
  color: var(--light-text);
  font-weight: 500;
  font-size: 2em;
}

.img-link:nth-child(2) {
  place-content: center end;
}

.img-link span {
  background: hsla(0, 0%, 0%, 0.80);
  padding: .5em 1em;
}

.link, .link:visited {
  color: var(--btn-bg-secondary);
}

.link-underline:hover {
  text-decoration: underline;
}

ul.nav-sidebar, ul.search-results, ul {
  list-style-type: none;
  margin-block: 0;
  padding: 0;
}

.grid header {
  text-align: center;
}

.grid table {
  justify-self: center;
  border-spacing: 0;
  border: solid var(--outset-shadow);
  border-radius: 1em;
  border-width: 1px;
  overflow: hidden;
}

.search-results {
  display: flex;
  flex-flow: column;
  outline: 1px solid var(--inset-shadow);
  position: absolute;
  z-index: 1;
  width: 100%;
}

.search-result {
  background-color: var(--light-text);
  padding: 1em;
  border-top: 1px solid var(--inset-shadow);
  font-size: .75em;
}

.search-result:hover {
  background: var(--btn-bg-primary);
  cursor: pointer;
}

.search-result span {
  float: right;
  font-style: italic;
}

.warning {
  color: var(--warning-text);
}

.workout-item {
  margin: 1.5em 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

.workout-item h2 {
  width: 90%;
  font-weight: 400;
}

.workout-wrapper {
  max-width: 90%;
}

.workout-section {
  transition: height 200ms;
  overflow: hidden;
  height: calc(100% - 2em);
}

.workout-section.hidden-workouts {
  height: 2rem;
}

.workout-section,
#create-workout-form h1 {
  user-select: none;
}

.workout-section h2 {
  margin: 0;
}

.workout-section>h2 {
  position: relative;
}

.workout-section>h2:hover::before {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.workout-section>h2::after {
  content: '>';
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(90deg);
  transition: all 200ms;
}

.workout-section>h2::before {
  position: absolute;
  content: '';
  width: calc(100% + 2rem);
  height: 4rem;
  background-color: rgba(0, 0, 0, 0.1);
  top: -1rem;
  left: -1rem;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.workout-section>h2.hidden-workouts::before {
  border-radius: var(--border-radius);
}

.workout-section>h2.hidden-workouts::after {
  transform: rotate(0deg);
}

.width-50 {
  width: 50%;
}

.width-100 {
  width: 100%;
}

.profile-pic {
  border-radius: 50%;
}

.nav-icon {
  width: 32px;
}

nav a, nav a:visited {
  color: var(--light-text);
}

nav a {
  padding: min(calc((var(--nav-height) - 1.1em)/2), 2em) 0;
  display: block;
  font-weight: 700;
}

fieldset {
  border: none;
}

fieldset button {
  width: 8vw;
  max-width: 8em;
  padding: 1.25em 0;
}

table {
  width: 80%;
}

table th {
  width: 50%
}

table td, table th {
  padding: 1em;
  text-align: center;
}

nav {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 0;
  left: 0;
  color: var(--light-text);
  width: 100%;
}

#profile-prefs-form h1 {
  margin: .67em 0 0;
}

#profile-prefs-form input {
  margin-top: .5em;
}

#nav-login-button {
  grid-area: 1/3;
}

#landing-main {
  overflow: hidden;
  padding: 1em;
}

#landing-header {
  margin-bottom: 2em;
}

/* *Mobile Styles  */
@media screen and (min-width: 320px) and (max-width: 768px) {
  fieldset {
    border: none;
  }

  fieldset button {
    width: 22vw;
    padding: 1.25em 0;
  }

  table th {
    width: 50%;
  }

  .grid table {
    width: 80vw;
    overflow: hidden;
  }

  .img-link {
    height: 30vh;
  }

  form.grid {
    row-gap: 1em;
  }

  nav.col-2 {
    grid-template-columns: 25% 1fr;
  }

  .mobile-nav ul {
    text-align: center;
  }

  .workout.grid {
    row-gap: 2em;
    width: 90vw;
    margin: 1em auto;
  }

  .workout-wrapper {
    max-width: 100%;
  }

  .logoutBtn {
    margin: 5vh 0 10vh;
  }

  #root {
    overflow-x: hidden;
    height: 100%;
  }

  #profile-prefs-form,
  #create-workout-form {
    margin: 1em 5vw;
  }

  #create {
    background-image: url(../content/ropes-small.jpg);
  }

  #history {
    background-image: url(../content/calendar-small.jpg);
  }

  #calculate {
    background-image: url(../content/plates-small.jpg);
  }

  #workoutlog-main header {
    grid-column: span 1;
  }
}

/* *Desktop styles  */
@media screen and (min-width: 769px) {
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }

  table {
    margin: 1em;
  }

  nav section.grid {
    padding: 1em;
    max-width: 12em;
  }

  main {
    padding: 0 5%;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
  }

  main>form {
    width: 80%;
  }

  .img-link {
    place-content: end center;
  }

  .img-link span {
    height: 10vh;
  }

  .img-link, .img-link span {
    border-radius: var(--border-radius);
  }

  .nav-links li, .nav-links li a {
    width: 15vw;
    text-align: center;
  }

  .nav-links a:hover {
    background-color: var(--inset-shadow);
  }

  nav.col-2 {
    grid-template-columns: 25% 50%;
  }

  .workout.grid {
    margin: 1em 0;
    row-gap: 2em;
    width: 100%;
  }

  #create {
    background-image: url(../content/ropes-med.jpg);
    background-position: right;
  }

  #history {
    background-image: url(../content/calendar-med.jpg);
  }

  #calculate {
    background-image: url(../content/plates-med.jpg);
  }

  #calculate, #history, #create {
    height: 80%;
  }

  #calculator-form {
    row-gap: 1em;
  }

  #home.grid {
    column-gap: 1em;
    height: 75vh;
    text-align: center;
  }

  #profile-prefs-form {
    row-gap: 1em;
  }

  #profile-main {
    grid-template-columns: 1fr;
    max-width: 25em;
    place-items: center;
    row-gap: 2em;
  }

  #home h1 {
    font-weight: 500;
  }

  #effort-table {
    grid-area: 2 / 2;
    transform: translateY(-35vh);
  }

  #create-workout-form {
    margin: 1em 0;
    row-gap: 1em;
  }

  #finish-workout-form {
    grid-area: 2 / 1;
    height: 2em;
  }
}
